import React from 'react';
import cx from 'classnames';
import { nameInitials } from '../../utils/functions';
import { defaultConfigToApply } from '../../utils/randomPhoto';
import { Avatar } from '../Avatar';
import styles from './style.module.css';

interface Props {
  name?: string;
  srcImg?: string;
  label?: string;
  className?: string;
  isInModal?: boolean;
}

export const CardHeader: React.FC<Props> = ({
  name = 'Nome da Loja',
  srcImg,
  label,
  className = '',
  isInModal = false,
}) => {
  const avatarCss = cx('inline-flex', styles.avatar);

  const renderImg = () => {
    if (srcImg) {
      return <Avatar cssStyles={avatarCss} src={srcImg} alt={name} />;
    }

    const imgCard = cx(
      'br-100 overflow-hidden b--gray ba contain bg-center bg-white mr8',
      styles.imgCard,
    );

    const imgStyle = { backgroundColor: defaultConfigToApply.bgColor };

    return (
      <div className={imgCard} style={imgStyle}>
        <p className="br-100 flex justify-center h-100 items-center f44 abbey fw1 ma0">
          {nameInitials(name)}
        </p>
      </div>
    );
  };

  const labelContainerClasses = cx(`w-100 ${styles.title}`, {
    ['inline-flex']: !isInModal,
  });

  return (
    <header className={className}>
      {renderImg()}
      <div className={labelContainerClasses}>
        {label && <span className="trueno f16 fw1 abbey">{label}</span>}
        <h3 className="f16 trueno abbey mv0">{name}</h3>
      </div>
    </header>
  );
};
