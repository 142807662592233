import { useState, useEffect } from 'react';
import { GetRatings } from '../services/ratings';
import { UseRatingsProps, RatingTypes } from '../types';

export function useRatings({ id }: UseRatingsProps) {
  const [ratingData, setRatingData] = useState<RatingTypes[]>([]);

  useEffect(() => {
    const getDataRating = async () => {
      const response = await GetRatings.getRatings(id);
      setRatingData(response);
    };
    getDataRating();
  }, [id]);

  return ratingData;
}
