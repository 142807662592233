import React from 'react';
import MediaQuery from 'react-responsive';
import { PARTS_NUMBER } from '../../../../../utils/productType';
import { ShopCardCars } from '../ShopCardCars';

interface Props {
  data: any;
  inStock: string;
  renderFilterSpecificVehicle: React.ReactNode;
  type?: number;
}

export const ShopCardsTablet = ({
  data,
  inStock,
  renderFilterSpecificVehicle,
  type = 1,
}: Props) => {
  const text = type === PARTS_NUMBER ? 'peças' : 'veículos';
  const subText = type === PARTS_NUMBER ? ' à venda' : ' em estoque';

  return (
    <MediaQuery minWidth={768} maxWidth={1023}>
      <ShopCardCars
        bolderTextSection={`${inStock} ${text}`}
        noPB
        restTextSection={subText}
        cardsData={data}
        renderFilterSpecificVehicle={renderFilterSpecificVehicle}
      />
    </MediaQuery>
  );
};
