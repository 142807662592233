import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import _map from 'lodash/map';
import { Icon } from '../../components/Icons';
import { getIcons, Icons } from '../../utils/functions';
import styles from './style.module.css';

interface Props {
  services: string[];
  icons?: Icons[];
}

interface CMSData {
  allDirectusTipoDeServico: {
    nodes: Icons[];
  };
}
const formatService = (services: string) => {
  const originalService = services;
  const formatedServices = originalService.split('/');
  if (formatedServices.length > 1) {
    return _map(formatedServices, service => {
      return (
        <>
          <span className="mt8 db abbey f20 trueno fw6">{service}</span>
        </>
      );
    });
  } else {
    return <span className="mt8 db abbey f20 trueno fw6">{originalService}</span>;
  }
};
const RenderAvailableServices: React.FC<Props> = ({ services, icons }) => {
  const renderList = () => {
    return _map(services, service => {
      return (
        <li
          className={`list flex flex-column items-center pa12 w100-l w50-m w50-ns ${styles.itemList}`}
        >
          <div className="flex justify-center items-center">
            <Icon name={getIcons(service, icons)} className={styles.icon} />
          </div>
          {formatService(service)}
        </li>
      );
    });
  };
  return <ul className="w-100 ma0 pa0 flex flex-wrap">{renderList()}</ul>;
};

export const AvailableServices: React.FC<Props> = props => {
  const services = graphql`
    query {
      allDirectusTipoDeServico {
        nodes {
          name
          icon
        }
      }
    }
  `;

  const renderComponent = (data: CMSData) => {
    const {
      allDirectusTipoDeServico: { nodes },
    } = data;

    return <RenderAvailableServices icons={nodes} {...props} />;
  };

  return <StaticQuery query={services} render={renderComponent} />;
};
