import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { navigate } from 'gatsby';
import { get, concat, last, isEmpty } from 'lodash';
import { AvailableServices } from '../../components/AvailableServices';
import { Button } from '../../components/Button';
import { Container } from '../../components/Container';
import { Divider } from '../../components/Divider';
import { EmptyState } from '../../components/EmptyState';
import { FilterSpecificVehicle } from '../../components/FilterSpecificVehicle';
import { Grid } from '../../components/Grid';
import { GridCell } from '../../components/GridCell';
import { LoaderFullPage } from '../../components/Loaders';
import { SharedModal } from '../../components/SharedModal';
import { StyledModal } from '../../components/StyledModal';
import { State as StoreState } from '../../pages/loja';
import { PARTS_NUMBER, CAR_NUMBER } from '../../utils/productType';
import { defaultConfigToApply } from '../../utils/randomPhoto';
import { shareInfo } from '../../utils/shareInfo';
import { typePerson } from '../../utils/typePerson';
import { Footer } from '../Footer';
import { Menu } from '../Menu';
import { Top } from '../Menu/components/MenuAd';
import { CommentShop } from '../modals/CommentShop';
import { RatingModule } from '../RatingModule';
import { SectionBanner } from './components/SectionBanner';
import { SectionCardsCars } from './components/SectionCardsCars/index';
import { SectionShopData } from './components/SectionShopData';
import { formattedShopData } from './components/SectionShopData/utils/formatShopData';
import { ShopAdsService, FindService } from './services/ShopAdsService';
import { ShopDataService } from './services/ShopDataService';
import styles from './style.module.css';

interface Props {
  location: any;
  onLoad: (state: StoreState) => void;
  autoscarStore: any;
  pageContextId: number;
  dataValue?: any;
}

export const Shop = ({ location, onLoad, autoscarStore, pageContextId, dataValue }: Props) => {
  const shopDataState = autoscarStore.autoscarId === pageContextId ? autoscarStore : {};
  const [shopData, setShopData] = useState<any>(shopDataState);

  const { bgImage } = defaultConfigToApply;

  const s3 = process.env.FTP_IMAGENS;

  const getPath = (): string => {
    const path = location.pathname;

    if (path.substr(path.length - 1) === '/') {
      return path.substr(0, path.length - 1);
    }
    return path;
  };

  const idUser = get(location, 'pathname', '') ? last(getPath().split('/')) || '' : '';

  const [shopAds, setShopAds] = useState<any>();
  const [shopAdsParts, setShopAdsParts] = useState<any>();
  const [inStock, setInStock] = useState<string>('');
  const [inStockParts, setInStockParts] = useState<string>('');
  const [showLoader, setShowLoader] = useState(true);
  const [shareData, setShareData] = useState<any>();
  const [paginate, setPaginate] = useState(0);
  const [paginateParts, setPaginateParts] = useState(0);
  const [thereIsMore, setThereIsMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [openModalStore, setOpenModalStore] = useState(false);
  const [openModalComment, setOpenModalComment] = useState(false);
  const [openRatingModal, setOpenRatingModal] = useState(false);
  const [services, setServices] = useState<any>();

  const gridBtn = cx('mb48 pb-8 mt32 mt48-ns mt48-m mt48-l f16 nowrap', styles.gridBtn);

  const getBanner = get(shopData, 'urlBannerPhoto', null) || get(shopData, 'urlFrontagePhoto', '');

  const dataRedirectSearch = {
    name: get(shopData, 'fantasyName', ''),
    city: get(shopData, 'city', ''),
    state: get(shopData, 'state', ''),
  };

  const banner = getBanner ? `${s3}${getBanner}` : bgImage;

  const shopAdsData = {
    idUser,
    page: paginate,
    take: 12,
    order: {
      brand: 'asc',
    },
  };

  const shopAdsDataParts = {
    idUser,
    page: paginateParts,
    take: 12,
  };

  const toggleModalStore = () => {
    setOpenModalStore(!openModalStore);
  };

  const handleToggleModalComment = () => setOpenModalComment(!openModalComment);

  const handleToggleRatingModal = () => setOpenRatingModal(!openRatingModal);

  useEffect(() => {
    setIsLoading(true);
    const fetchShopAds = async (fetchShopDataResponse?: any) => {
      const responseAds = await ShopAdsService.getShopAds(shopAdsData)
        .then(res => res)
        .catch(err => err);

      setShopAds(responseAds.data);
      setIsLoading(false);
      setInStock(
        responseAds.headers && responseAds.status !== 204
          ? responseAds.headers['content-range']
          : 0,
      );

      if (fetchShopDataResponse) {
        const schemaShopData = formattedShopData(fetchShopDataResponse);
        onLoad({
          ...schemaShopData,
          personName: fetchShopDataResponse.name,
          responseAds,
        });
      }

      setShareData(shareInfo());
      setPaginate(paginate + 1);
      setShowLoader(false);
    };

    const fetchShopAdsParts = async () => {
      const responseAds = await ShopAdsService.getShopAdsParts(shopAdsDataParts)
        .then(res => res)
        .catch(err => err);

      setShopAdsParts(responseAds.data);
      setIsLoading(false);
      setInStockParts(
        responseAds.headers && responseAds.status !== 204
          ? responseAds.headers['content-range']
          : 0,
      );

      setShareData(shareInfo());
      setPaginate(paginate + 1);
      setShowLoader(false);
    };

    const fetchContacts = async () => {
      const response = await ShopDataService.getShopData(idUser)
        .then(res => res.data.contacts)
        .catch(err => err);

      const phoneContacts = response.filter((contact: { type: string }) => contact.type === '1');

      setShopData({
        ...shopData,
        contacts: phoneContacts,
      });
    };

    const fetchShopData = async () => {
      const response = await ShopDataService.getShopData(idUser)
        .then(res => res.data)
        .catch(err => err);

      if (response.statusCode === 404) {
        return navigate('/404');
      }

      setShopData(response);

      fetchShopAds(response);
    };

    const fetchServices = async () => {
      const response = await FindService.getServices(idUser)
        .then(res => res.data)
        .catch(err => err);

      if (response.statusCode === 404) {
        return navigate('/404');
      }
      setServices(response);
    };

    if (isEmpty(shopData)) {
      fetchShopData();
      return;
    }
    fetchShopAds();
    fetchServices();
    fetchShopAdsParts();
    fetchContacts();
  }, []);

  if (showLoader) {
    return <LoaderFullPage />;
  }

  const handlePaginate = async (type?: number) => {
    setIsLoading(true);

    if (type === PARTS_NUMBER) {
      setPaginateParts(paginateParts + 1);
      const responseAdsParts = await ShopAdsService.getShopAdsParts({
        idUser,
        page: paginateParts + 1,
        take: 12,
      })
        .then(res => res)
        .catch(err => err);
      setThereIsMore(responseAdsParts.data.length > 0);
      setShopAdsParts(concat(shopAdsParts, responseAdsParts.data));
      setInStock(responseAdsParts.headers ? responseAdsParts.headers['content-range'] : 0);
      setIsLoading(false);
    }

    setPaginate(paginate + 1);
    const responseAds = await ShopAdsService.getShopAds(shopAdsData)
      .then(res => res)
      .catch(err => err);
    setThereIsMore(responseAds.data.length > 0);
    setShopAds(concat(shopAds, responseAds.data));
    setInStock(responseAds.headers ? responseAds.headers['content-range'] : 0);
    setIsLoading(false);
  };

  const handleSeeMore = (type: number) => {
    const item = type === PARTS_NUMBER ? shopAdsParts : shopAds;
    if (item && item.length % 12 === 0 && thereIsMore) {
      const text = type === PARTS_NUMBER ? 'peças' : 'veículos';
      return (
        // tslint:disable-next-line jsx-no-lambda
        <Button onClick={() => handlePaginate(type)} classButton={gridBtn} loading={isLoading}>
          Carregar mais {text}
        </Button>
      );
    }
    return null;
  };

  const renderAvailableServices = () => {
    if (!services || !services.services || !services.services.length) {
      return <div className="mb32" />;
    }

    return (
      <Container style={{ margin: 0 }}>
        <div className="ph24-l">
          <h2 className="trueno fw2 f24 abbey fw2 ma0 mb16 mt32 self-start">
            <b className="fw6">Serviços</b> disponíveis:
          </h2>
          <AvailableServices {...services} />
          <Divider className="mv32" />
        </div>
      </Container>
    );
  };

  // const renderFilterSpecificVehicle = (typeVehicle?: number) => {
  //   const type = get(shopData, 'type', '');

  //   if (type === '1' || !shopAds || !shopAds.length) {
  //     return null;
  //   }

  //   return <FilterSpecificVehicle data={dataRedirectSearch} typeVehicle={typeVehicle} />;
  // };

  const renderFilterSpecificVehicle = (typeVehicle?: number) => {
    const type = get(shopData, 'type', '');

    if (type === '1' || !shopAds || !shopAds.length) {
      return null;
    }

    return <FilterSpecificVehicle data={dataRedirectSearch} typeVehicle={typeVehicle} />;
  };

  const renderSectionCardsParts = () => {
    if (!shopAdsParts || !shopAdsParts.length) {
      return null;
    }

    return (
      <>
        <SectionCardsCars
          data={shopAdsParts}
          inStock={inStockParts}
          renderFilterSpecificVehicle={renderFilterSpecificVehicle(8)}
          type={8}
        />
        {handleSeeMore(PARTS_NUMBER)}
      </>
    );
  };

  const renderCards = () => {
    if ((!shopAds || !shopAds.length) && (!shopAdsParts || !shopAdsParts.length) && !services) {
      const onSearchAgain = () => navigate('anuncios/busca');
      return (
        <EmptyState
          onSearchAgain={onSearchAgain}
          textInfo="Não encontramos nenhum resultado."
          imgEmpty="images/search/empty-state.png"
          btnText="Ver todas as ofertas"
        />
      );
    }
    return (
      <>
        {renderSectionCardsParts()}
        {renderSectionCardsCars()}
      </>
    );
  };

  const renderSectionCardsCars = () => {
    if (!shopAds || !shopAds.length) {
      return null;
    }

    return (
      <>
        <SectionCardsCars
          data={shopAds}
          inStock={inStock}
          renderFilterSpecificVehicle={renderFilterSpecificVehicle()}
        />
        {handleSeeMore(CAR_NUMBER)}
      </>
    );
  };

  const ratingModuleProps = {
    srcImg: get(shopData, 'urlCompanyLogo', ''),
    label: 'Comentários Sobre:',
    name: get(shopData, 'fantasyName', ''),
    id: get(shopData, 'autoscarId', ''),
  };

  return (
    <>
      <div className="db db-ns dn-m dn-l">
        <Top handleShare={toggleModalStore} dataValue={dataValue} />
      </div>
      <div className="dn db-m db-l">
        <Menu menuHasTwoColors={false} />
      </div>
      <div className="pt48 pt72-m pt72-l bg-wild-sand">
        <SectionBanner
          urlBanner={banner}
          onClick={toggleModalStore}
          shopName={typePerson(shopData)}
          dataValue={dataValue}
        />

        <Grid className="flex flex-column flex-row-m flex-row-l justify-between">
          <GridCell width={[1, 1, 3 / 12]} className="bg-white">
            <SectionShopData
              shopData={shopData}
              handleToggleModalComment={handleToggleModalComment}
              handleToggleRatingModal={handleToggleRatingModal}
              services={services}
              dataValue={dataValue}
            />
          </GridCell>

          <GridCell className="flex flex-column items-center pb8 pb24-m" width={[1, 1, 9 / 12]}>
            {renderAvailableServices()}
            {renderCards()}
          </GridCell>
        </Grid>
      </div>
      <Footer />

      <SharedModal
        typeShared="loja"
        isOpenModal={openModalStore}
        controlModal={toggleModalStore}
        dataShare={shareData}
      />

      <StyledModal
        isOpen={openRatingModal}
        closeModal={handleToggleRatingModal}
        className={styles.modal}
      >
        <RatingModule {...ratingModuleProps} />
      </StyledModal>

      <CommentShop
        isOpen={openModalComment}
        onClose={handleToggleModalComment}
        shopData={shopData}
      />
    </>
  );
};
