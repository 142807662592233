import get from 'lodash/get';
import { defaultConfigToApply } from '../../../../../utils/randomPhoto';
import { typePerson } from '../../../../../utils/typePerson';

export const formattedShopData = (data: any) => {
  const s3 = process.env.FTP_IMAGENS;
  const { bgColor } = defaultConfigToApply;
  const { contacts } = data;
  const newData = {
    urlImg: get(data, 'urlCompanyLogo', ''),
    logoColor: bgColor,
    s3,
    phone: get(data, 'phone', ''),
    whatsapp: get(data, 'whatsapp', ''),
    userSince: '2 anos',
    announcedProducts: '500 veículos',
    address: {
      city: `${get(data, 'city', '')} - ${get(data, 'state', '')}`,
      cepAddress: get(data, 'cep', '--'),
      district: get(data, 'addressDistrict', ''),
      street: `${get(data, 'address', '')},${get(data, 'addressNumber', '')}`,
      additionalAddressData: get(data, 'additionalAddressData', ''),
    },
    name: typePerson(data),
    type: `${get(data, 'type', '')}`,
    contacts,
  };
  return newData;
};
