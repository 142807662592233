import React from 'react';
import cx from 'classnames';
import { Icon } from '../../../../../components/Icons';
import styles from './styles.module.css';

interface Props {
  isForm: boolean;
  shouldRender?: boolean;
}

export const ErrorMessage = ({ isForm, shouldRender = true }: Props) => {
  if (!shouldRender) {
    return null;
  }

  const cxMain = cx(
    {
      mt16: isForm,
      mt24: !isForm,
    },
    'pv12 pl12-m flex items-center items-center-m justify-center justify-start-m',
    styles.bgWarning,
    styles.changesSaved,
  );

  return (
    <div className={cxMain}>
      <Icon name="SvgIconWarning" width={36} height={36} />
      <div className="fw6 f14 black-50 ml12 mt8-m flex flex-column flex-row-m">
        <span> Preencha todos os campos corretamente </span>
      </div>
    </div>
  );
};
