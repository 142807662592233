import React from 'react';
import cx from 'classnames';
import { Link } from 'gatsby';
import { PARTS_NUMBER } from '../../../utils/productType';
import { Icon } from '../../Icons';
import styles from '../style.module.css';

interface DataProps {
  name: string;
  city: string;
  state: string;
}
interface Props {
  data: DataProps;
  typeVehicle?: number;
}
export const ResultFilter = ({ data, typeVehicle }: Props) => {
  const { name, city, state } = data;
  const linkClass = cx(
    'f12 f18-m f18-l fw6 red pointer ml4-ns relative no-underline',
    styles.linkClass,
  );

  const linkUrl =
    typeVehicle === PARTS_NUMBER
      ? `/anuncios/busca/?categoria=pecas&cidade=${city}-${state}&loja=${name}`
      : `/anuncios/busca/?categoria=Carros&cidade=${city}-${state}&loja=${name}`;

  return (
    <Link to={linkUrl} className={linkClass} tabIndex={0}>
      Filtrar resultados
      <Icon name="SvgIconArrowRightBold" width={10} height={10} className="ml8" />
    </Link>
  );
};
