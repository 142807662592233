import React from 'react';
import MediaQuery from 'react-responsive';
import { PARTS_NUMBER } from '../../../../../utils/productType';
import { ShopCardCars } from '../ShopCardCars';

interface Props {
  data: any;
  inStock: string;
  renderFilterSpecificVehicle: React.ReactNode;
  type?: number;
}

export const ShopCardsDesktop = ({ data, inStock, renderFilterSpecificVehicle, type }: Props) => {
  const greaterThan: boolean = Number(inStock) > 1;
  const text =
    type === PARTS_NUMBER ? (greaterThan ? 'peças' : 'peça') : greaterThan ? 'veículos' : 'veículo';
  const subText = type === PARTS_NUMBER ? ' à venda' : ' em estoque';

  return (
    <MediaQuery minWidth={1024}>
      <ShopCardCars
        bolderTextSection={`${inStock} ${text}`}
        noPB
        restTextSection={subText}
        cardsData={data}
        renderFilterSpecificVehicle={renderFilterSpecificVehicle}
        type={type}
      />
    </MediaQuery>
  );
};
