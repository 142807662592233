import React from 'react';
import { Container } from '../../../../components/Container';
import { CAR_NUMBER } from '../../../../utils/productType';
import { ShopCardsDesktop } from './components/ShopCardsDesktop';
import { ShopCardsMobile } from './components/ShopCardsMobile';
import { ShopCardsTablet } from './components/ShopCardsTablet';
import { CardDataProps } from './ShopCardCars';
import { formattedShopData } from './utils/formatShopCardsData';

interface Props {
  data: CardDataProps;
  inStock: string;
  renderFilterSpecificVehicle: React.ReactNode;
  type?: number;
}
export const SectionCardsCars = ({
  data,
  inStock,
  renderFilterSpecificVehicle,
  type = CAR_NUMBER,
}: Props) => {
  const newData = formattedShopData(data);
  const { pristineData } = newData;

  return (
    <Container style={{ margin: 0 }}>
      <ShopCardsDesktop
        data={pristineData}
        inStock={inStock}
        renderFilterSpecificVehicle={renderFilterSpecificVehicle}
        type={type}
      />
      <ShopCardsTablet
        data={pristineData}
        inStock={inStock}
        renderFilterSpecificVehicle={renderFilterSpecificVehicle}
        type={type}
      />
      <ShopCardsMobile
        data={pristineData}
        inStock={inStock}
        renderFilterSpecificVehicle={renderFilterSpecificVehicle}
        type={type}
      />
    </Container>
  );
};
