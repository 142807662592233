import React, { useEffect, useState } from 'react';
import { CardHeader } from '../../components/CardHeader';
import { CardOfRating } from '../../components/CardOfRating';
import { Loader } from '../../components/Loaders';
import { useRatings } from './hooks/useRatings';
import styles from './style.module.css';
import { RatingModuleProps, RatingTypes } from './types';

export const RatingModule: React.FC<RatingModuleProps> = ({ name, srcImg, label, id }) => {
  const [dataRating, setDataRating] = useState<RatingTypes[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const useRating = useRatings({ id });
  const cardHeaderProps = {
    name,
    srcImg,
    label,
    className: 'pa16 w-100 shadow-2 flex items-center',
    isInModal: true,
  };

  useEffect(() => {
    setDataRating(useRating);
    setIsLoading(!useRating.length);
  }, [useRating]);

  const renderRatingList = () => {
    return (
      !!dataRating.length &&
      dataRating.map(item => {
        return <CardOfRating key={item.id} {...item} />;
      })
    );
  };

  return (
    <>
      <CardHeader {...cardHeaderProps} />
      <section className={styles.ratings}>{isLoading ? <Loader /> : renderRatingList()}</section>
    </>
  );
};
