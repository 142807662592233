import React from 'react';
import cx from 'classnames';
import styles from './style.module.css';

interface Props {
  src: string;
  alt?: string;
  cssStyles?: string;
}

export const Avatar: React.FC<Props> = ({ src, alt, cssStyles }) => {
  const avatarCss = cx(cssStyles, styles.avatar);

  return (
    <div className={avatarCss}>
      <img className={styles.img} src={src} alt={alt} />
    </div>
  );
};
