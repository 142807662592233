import React, { useEffect, useState } from 'react';
import _pick from 'lodash/pick';
import { Loader } from '../../../components/Loaders';
import { ModalSuccessAndError } from '../../../components/ModalSuccessAndError';
import { StyledModal } from '../../../components/StyledModal';
import { getUserServices } from '../../EditProfile/services/editProfile';
import { FormComment } from './components/FormComment';
import { ratingData, saveRating } from './services/rating.service';
import styles from './style.module.css';

export interface InitialValues {
  id?: number;
  rating: number;
  comment: string;
}

interface Props {
  shopData: any;
  isMobile: boolean;
  isOpen: boolean;
  onClose: () => void;
}

export const CommentShop = ({ shopData, isMobile, isOpen, onClose }: Props) => {
  const { autoscarId, id } = shopData;
  const defaultValues = { id: 0, comment: '', rating: 0 };
  const [serviceId, setServiceId] = useState<number>(0);
  const [initialValues, setInitialValues] = useState<InitialValues>(defaultValues);
  const [isSuccessfully, setSuccessfully] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchServices = async () => await getUserServices(autoscarId || id);
  const fetchRating = async (getServiceId: number) => await ratingData(getServiceId);

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      fetchServices().then(
        data => {
          setServiceId(data.id);

          if (!data.id) {
            setLoading(false);
            return;
          }

          fetchRating(data.id).then(
            resp => {
              const evaluation = _pick(resp.data, ['comment', 'id', 'note']);
              const values = { rating: parseInt(evaluation.note, 10), ...evaluation };
              delete values.note;
              setInitialValues(values);
              setLoading(false);
            },
            () => {
              setLoading(false);
            },
          );
        },
        () => {
          setLoading(false);
        },
      );
    }

    return () => {
      setServiceId(0);
      setHasError(false);
      setInitialValues(defaultValues);
    };
  }, [isOpen]);

  const submitData = (values: any) => {
    const closeError = () => {
      setTimeout(() => {
        setHasError(false);
      }, 5000);
    };

    if (values.rating === 0 || values.comment === '') {
      setHasError(true);
      closeError();
      return;
    }
    setLoading(true);

    saveRating({
      adPartsServicesId: serviceId,
      comment: values.comment,
      note: values.rating,
    }).then(
      () => {
        onClose();
        setLoading(false);
        setSuccessfully(true);
      },
      () => {
        setLoading(false);
        setHasError(true);
        closeError();
      },
    );
  };

  const controlModal = () => {
    setSuccessfully(false);
  };

  const renderContent = () => {
    if (loading) {
      return <Loader />;
    }

    return (
      <FormComment
        shopData={shopData}
        initialValues={initialValues}
        submitData={submitData}
        hasError={hasError}
      />
    );
  };

  return (
    <>
      <StyledModal
        isOpen={isOpen}
        className={styles.modalClass}
        backButton={isMobile}
        closeModal={onClose}
        loading={loading}
      >
        {renderContent()}
      </StyledModal>
      <ModalSuccessAndError
        title="Parabéns!"
        subTitle="Seu comentário foi enviado com sucesso."
        isOpen={isSuccessfully}
        success
        closeModal={controlModal}
        actionBtnSecondary={controlModal}
      />
    </>
  );
};

CommentShop.defaultProps = {
  isMobile: false,
};
