import React from 'react';
import cx from 'classnames';
import get from 'lodash/get';
import map from 'lodash/map';
import { CardAd } from '../../../../components/CardAd';
import { Grid } from '../../../../components/Grid';
import { GridCell } from '../../../../components/GridCell';
import { WrapperSectionHome } from '../../../../components/WrapperSectionHome';
import { UserData } from '../../../Login/components/SignIn/store/user';
import styles from './style.module.css';

export interface ModelsCardDataProps {
  brandName: string;
  fabricationYear: number;
  fipeId?: number;
  id: number;
  modelYear: number;
  motor: number;
  name: string;
  status: string;
  type: string;
  version: string;
}

export interface CardDataProps {
  city: string;
  description: string;
  id: number;
  mileage: number;
  model: ModelsCardDataProps;
  photoUrl: string[];
  state: string;
  url: string;
  price: number | string;
  user?: UserData;
  name?: string;
}

interface Props {
  initialTextSection?: string;
  bolderTextSection: string;
  restTextSection?: string;
  cardsData: CardDataProps[];
  noPB: boolean;
  renderFilterSpecificVehicle: React.ReactNode;
  type?: number;
}

export const ShopCardCars = ({
  bolderTextSection,
  restTextSection,
  initialTextSection,
  cardsData,
  noPB,
  renderFilterSpecificVehicle,
  type = 1,
}: Props) => {
  // const styleSectionCard = noPB
  //   ? 'mt40 mt48-ns mt72-m mt72-l pr24-l pl24-l pl16-m pr16-m'
  //   : 'pt8 pr24-l pl24-l';
  const styleSectionCard = noPB ? 'ph24-l' : 'pt8 ph24-l';

  const renderCards = () => {
    return map(cardsData, (offer, idx) => {
      const { mileage, model, url, price, description } = offer;

      const photoUrl = get(offer, 'photoUrl', []);
      const user = get(offer, 'user', { type: '' });
      let carName = offer.name;
      let modelYearVehicle = 0;
      let fabricationYearVehicle = 0;

      if (model) {
        const { name, modelYear, fabricationYear, brandName, version } = model;
        carName = `${brandName} ${name} ${version}`;
        modelYearVehicle = modelYear;
        fabricationYearVehicle = fabricationYear;
      }
      const userNameAd = user
        ? get(user, user.type === 1 ? 'name' : 'fantasyName', '') || ''
        : description;

      const gridClass = cx('pt0-ns', styles.card, {
        'pb12 pb16-ns pb24-m pb24-l pr12 pr24-ns pr24-m pr24-l': noPB,
      });

      return (
        <GridCell key={`${idx}--${name}`} className={gridClass} width={[0.5, 0.33, 0.33, 0.25]}>
          <CardAd
            carName={carName || ''}
            modelYear={modelYearVehicle}
            fabricationYear={fabricationYearVehicle}
            km={mileage}
            clientName={userNameAd}
            price={price}
            urlPictures={photoUrl}
            url={url}
            vehicleType={type}
          />
        </GridCell>
      );
    });
  };

  const gridWrapper = cx('w-100 pb0 pb8-ns pb0-m pb0-l', styles.cardsWrapper);

  return (
    <div className={styleSectionCard}>
      <WrapperSectionHome
        initialText={initialTextSection}
        bolderText={bolderTextSection}
        restText={restTextSection}
        classTitle="trueno fw2 f24 abbey fw2 ma0"
        classGrid="mt12 mb12 mb16-ns mb24-m mb24-l"
        resolutionTitle={[1, 1, 1]}
        renderFilterSpecificVehicle={renderFilterSpecificVehicle}
      >
        <Grid className={gridWrapper}>{renderCards()}</Grid>
      </WrapperSectionHome>
    </div>
  );
};
