import { axiosInstance } from '../../../services/axios-instance';
import { formatDate } from '../../../utils/functions';
import { ResponseRatings } from '../types';

const responseAdapter = (data: ResponseRatings[]) => {
  return data.map((item: any) => {
    const { id, comment, createDate, note, user } = item;

    return {
      id,
      comment,
      createDate: formatDate(createDate),
      note,
      name: user.name,
    };
  });
};

export const GetRatings = {
  getRatings: (id: number) =>
    axiosInstance
      .get(`/advertisement/partServices/rating/findByService/${id}`)
      .then(res => {
        return responseAdapter(res.data);
      })
      .catch(err => err),
};
