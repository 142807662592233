import React, { FormEvent } from 'react';
import { Field } from 'formik';
import MediaQuery from 'react-responsive';
import { PrimaryButton } from '../../../../../components/Button/Primary';
import { Divider } from '../../../../../components/Divider';
import { customTextAreaComponent } from '../../../../VehicleRegistration/StepThree/components/StepThreeForm/StepThreeForm';
import { InitialValues } from '../../index';

interface Props {
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  values: InitialValues;
}

const ButtomDesktop = () => {
  return (
    <>
      <div className="fl w-75">&nbsp;</div>
      <div className="fl w-25">
        <PrimaryButton type="submit" size="medium">
          Enviar
        </PrimaryButton>
      </div>
    </>
  );
};

const ButtomMobile = () => {
  return (
    <>
      <div className="fl w-100">
        <PrimaryButton type="submit" size="medium">
          Enviar
        </PrimaryButton>
      </div>
    </>
  );
};

export const RenderForm = ({ handleSubmit, values, onChange }: Props) => {
  if (values.comment && values.id !== 0) {
    return (
      <>
        <Divider />
        <p className="f16 fw3">{values.comment}</p>
      </>
    );
  }
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="comment"
        id="comment"
        title="Deixe seu comentário"
        labeltext="Comentário"
        value={values.comment}
        onChange={onChange}
        component={customTextAreaComponent}
        maxLength={500}
        className="bw1 b--abbey"
        required
        rows={6}
      />
      <div className="pb24">
        <MediaQuery maxWidth={767}>
          <ButtomMobile />
        </MediaQuery>
        <MediaQuery minWidth={768}>
          <ButtomDesktop />
        </MediaQuery>
      </div>
    </form>
  );
};
