import React from 'react';
import cx from 'classnames';
import { PARTS_NUMBER } from '../../utils/productType';
import { Container } from '../Container';
import styles from '../FilterSpecificVehicle/style.module.css';
import { ResultFilter } from './components/ResultsFilter';

interface DataProps {
  name: string;
  city: string;
  state: string;
}
interface Props {
  data: DataProps;
  typeVehicle?: number;
}

export const FilterSpecificVehicle = ({ data, typeVehicle }: Props) => {
  const classSubtitle = cx('f12 f18-m f18-l fw2 abbey ma0', { dn: typeVehicle === PARTS_NUMBER });

  const classWrapper = cx('w-100 flex flex-row items-start mt16 mb24', {
    [styles.margin]: typeVehicle === PARTS_NUMBER,
    'justify-end': typeVehicle === PARTS_NUMBER,
    'justify-between': typeVehicle !== PARTS_NUMBER,
  });

  return (
    <Container className={cx(styles.marginAuto)}>
      <div className={classWrapper}>
        <p className={classSubtitle}>
          Buscando um <b className="fw7">veículo específico</b> nessa loja?
        </p>
        <ResultFilter data={data} typeVehicle={typeVehicle} />
      </div>
    </Container>
  );
};
