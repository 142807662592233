import { axiosInstanceWithAuth, axiosInstance } from '../../../services/axios-instance';
import { ServiceData } from '../interfaces/userServices';

export const putUserData = (userId: number, payload: any) => {
  if (payload.type === '1') {
    delete payload.type;
  }
  if (!payload.contacts || (payload.contacts && !payload.contacts[0])) {
    payload.contacts = [];
  }
  return axiosInstanceWithAuth.put(`/user/${userId}`, payload, {
    headers: { 'Content-Type': 'application/json' },
  });
};

export const updatePhotos = (userId: number, payload: any) => {
  if (!payload.addressNumber) {
    payload.addressNumber = 0;
  }
  return putUserData(userId, payload);
};

export const getUserDataById = (id: number) =>
  axiosInstanceWithAuth
    .get(`/user/${id}?t=${new Date().getTime()}`)
    .then(response => (response ? response.data : {}))
    .catch(error => console.error(error));

export const getUserServices = (userId: number) =>
  axiosInstance
    .get(`/advertisement/findServiceByUserId/${userId}?t=${new Date().getTime()}`, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(response => {
      if (response.status === 200) {
        return response.data || {};
      }
      throw new Error('Erro ao buscar serviços do usuário.');
    })
    .catch(error => console.error(error));

export const createServices = (
  { description, type, services, status, businessHours }: ServiceData,
  userId = 0,
) => {
  let url = '/advertisement/partServices/';
  if (userId) {
    url += `?userId=${userId}`;
  }
  return axiosInstanceWithAuth.post(
    url,
    { description, type, services, status, businessHours },
    {
      headers: { 'Content-Type': 'application/json' },
    },
  );
};

export const savePartServices = (
  id: number,
  userId: number,
  { description, type, services, status, businessHours, highlight }: ServiceData,
) => {
  if (id === 0 && userId !== 0) {
    return createServices(
      {
        description: description ? description : '',
        type,
        services: services || [],
        status,
        businessHours,
        highlight,
      },
      userId,
    );
  }

  businessHours = businessHours.map(item => {
    item.day = parseFloat(item.day);
    return item;
  });

  return axiosInstanceWithAuth.put(
    `/advertisement/partServices/${id}`,
    {
      description: description ? description : '',
      type,
      services: services || [],
      status: Number(status),
      businessHours,
      highlight,
    },
    {
      headers: { 'Content-Type': 'application/json' },
    },
  );
};
