import React from 'react';
import cx from 'classnames';
import { ReactStars } from './ReactStars';
import styles from './style.module.css';

interface Props {
  ratingChanged?: (newRating: number) => void;
  ratingValue: number;
  count?: number;
  edit?: boolean;
  reviewers?: number;
  handleReviews?: () => void;
  half?: boolean;
  className?: string;
}

const renderReviews = (reviewers: number, handleReviews?: () => void) => {
  if (!reviewers) {
    return null;
  }

  const reviewersStyles = cx(`fr trueno f12 abbey ${styles.countReviewers}`, {
    pointer: reviewers > 0,
  });

  return (
    <span onClick={handleReviews} className={reviewersStyles}>
      ({reviewers})
    </span>
  );
};

export const RatingViewer: React.FC<Props> = ({
  ratingChanged,
  ratingValue,
  handleReviews,
  className,
  count = 5,
  edit = true,
  reviewers = 0,
  half = true,
}) => {
  const reactStarsProps = {
    className: cx(className, styles.starsContent),
    count,
    onChange: ratingChanged,
    size: 20,
    color1: '#757575',
    color2: '#ffd700',
    edit,
    value: ratingValue,
    half,
    reviewers,
  };

  return (
    <>
      <ReactStars {...reactStarsProps} />
      {renderReviews(reviewers, handleReviews)}
    </>
  );
};
