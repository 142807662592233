import React, { useState } from 'react';
import cx from 'classnames';
import ReactBnbGallery from 'react-bnb-gallery';
import { ShareAndLike } from '../../../../components/ShareAndLike';
import styles from './style.module.css';

interface Props {
  urlBanner: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  shopName: string;
  dataValue?: any;
}
export const SectionBanner = ({ urlBanner, onClick, shopName, dataValue }: Props) => {
  const [openGallery, setOpenGallery] = useState(false);
  const toggleGallery = () => setOpenGallery(!openGallery);
  const bannerClass = cx(
    'relative overflow-hidden bg-center cover flex justify-center items-center',
    styles.banner,
  );
  const cxImage = cx('w-auto h-auto pointer', styles.image);

  const gallery = [
    {
      id: 0,
      photo: urlBanner,
    },
  ];

  return (
    <section className={bannerClass}>
      <img
        src={urlBanner}
        alt={`Banner da loja ${shopName}`}
        className={cxImage}
        onClick={toggleGallery}
      />
      <div className="dn flex-m flex-l mr24-m mb16-m mr48-l pl8-l mb16-l absolute right-0 bottom-0 z-2">
        <ShareAndLike onClick={onClick} dataValue={dataValue} />
      </div>
      <ReactBnbGallery
        show={openGallery}
        photos={gallery}
        onClose={toggleGallery}
        phrases={{ phrase: '' }}
        activePhotoIndex={0}
      />
    </section>
  );
};
