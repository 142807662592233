import React from 'react';
import { CompleteShopData } from '../../../../components/ShopData';
import { formattedShopData } from './utils/formatShopData';

interface Props {
  shopData: any;
  handleToggleModalComment: () => void;
  handleToggleRatingModal: () => void;
  services: any;
  dataValue?: string | any;
}

export const SectionShopData = ({
  shopData,
  handleToggleModalComment,
  handleToggleRatingModal,
  services,
  dataValue,
}: Props) => {
  if (!shopData) {
    return null;
  }

  const newData = formattedShopData(shopData);
  return (
    <>
      <CompleteShopData
        dataAddress={newData}
        handleToggleModalComment={handleToggleModalComment}
        handleToggleRatingModal={handleToggleRatingModal}
        services={services}
        dataValue={dataValue}
      />
    </>
  );
};
