import drop from 'lodash/drop';
import dropRight from 'lodash/dropRight';
import get from 'lodash/get';
import map from 'lodash/map';
import { urlFormatted } from '../../../../Home/utils/formattedUrl';

const formattedModel = (model: any) => {
  const { brandName, fabricationYear, fipeId, id, modelYear, motor, name, status, type, version } =
    model;
  return {
    id,
    name,
    type,
    motor,
    status,
    fipeId,
    version,
    brandName,
    modelYear,
    fabricationYear,
  };
};

export const formattedShopData = (data: any) => {
  const newData = map(data, item => {
    const { city, description, id, mileage, model, state, price, name } = item;

    return {
      price,
      city,
      description,
      id,
      mileage,
      model: model && formattedModel(model),
      photoUrl: get(item, 'photoUrl', {}),
      state,
      url: urlFormatted(item),
      name,
    };
  });

  const sizeMobile = newData.length - 3;
  const sizeTablet = sizeMobile - 1;

  return {
    resSplitted: dropRight(newData, sizeMobile),
    resSplitted768: dropRight(newData, sizeTablet),
    responseDroppped: drop(newData, 3),
    responseDroppped768: drop(newData, 4),
    pristineData: newData,
  };
};
