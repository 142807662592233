import React, { useState } from 'react';
import cx from 'classnames';
import { Formik } from 'formik';
import { NameInitials } from '../../../../../components/NameInitials';
import { nameInitials } from '../../../../../utils/functions';
import { InitialValues } from '../../index';
import styles from '../../style.module.css';
import { ErrorMessage } from '../Messages/ErrorMessage';
import { Evaluation } from './Evaluation';
import { RenderForm } from './RenderForm';

interface Props {
  shopData: any;
  initialValues: InitialValues;
  submitData: (values: any, formikActions: any) => void;
  hasError: boolean;
}

export const FormComment = ({ shopData, initialValues, submitData, hasError }: Props) => {
  const [values, setValues] = useState<InitialValues>(initialValues);
  const isEditable = values.rating === 0 || values.id === 0;
  const s3 = process.env.FTP_IMAGENS;
  const { fantasyName, companyName, name, urlCompanyLogo } = shopData;
  const nameChoice = fantasyName || companyName || name;

  const companyLogoStyle = cx(
    'br-100 overflow-hidden ba b--boulder w-10 mr12 justify-center',
    styles.imageCompanyLogo,
  );

  const renderLogo = () => {
    const companyLogo = urlCompanyLogo ? `${s3}${urlCompanyLogo}` : nameInitials(nameChoice);

    if (!urlCompanyLogo) {
      return <NameInitials name={nameChoice} />;
    }
    return <img src={companyLogo} alt={nameChoice} title={nameChoice} className="h-100 w-100" />;
  };

  const onChange = (e: any) => {
    const value = e.target.value;
    setValues({ ...values, comment: value });
  };

  const onChangeEvaluation = (rating: number) => {
    setValues({ ...values, rating });
  };

  const renderForm = (props: any) => (
    <RenderForm handleSubmit={submitData} onChange={onChange} {...props} />
  );

  return (
    <>
      <header className="pa12 pt16 pt16 abbey shadow-2 flex">
        <div className={companyLogoStyle}>{renderLogo()}</div>
        <div className="w-90 f16">
          <p className="fw2 mb0 mt8">Avalie a loja</p>
          <p className="mb0 mt0">{nameChoice}</p>
        </div>
      </header>
      <section className="pa32 pt24 f14 abbey">
        <p className="mb0">Como foi sua experiência?</p>
        <Evaluation
          onChange={onChangeEvaluation}
          ratingDefault={values.rating}
          isEditable={isEditable}
        />
        <Formik
          initialValues={values}
          onSubmit={submitData}
          enableReinitialize
          render={renderForm}
        />
        <ErrorMessage isForm shouldRender={hasError} />
      </section>
    </>
  );
};
