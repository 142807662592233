import React from 'react';
import { ReactStars } from '../RatingViewer/ReactStars';
import styles from './style.module.css';

interface Props {
  name: string;
  comment: string;
  note: number;
  createDate: string;
}

export const CardOfRating: React.FC<Props> = ({ name, comment, note, createDate }) => {
  const reactStarsProps = {
    value: note,
    size: 15.5,
    onChange: () => null,
    edit: false,
  };

  return (
    <article className={`${styles.cardArticle} bb`}>
      <header className="treuno f16 abbey b">{name}</header>
      <section className="trueno f12 abbey fw3 lh-copy">
        <p className="mv4">{comment}</p>
      </section>
      <footer className="mt8">
        <ReactStars {...reactStarsProps} />
        <b className="trueno f12 abbey ml16">{createDate}</b>
      </footer>
    </article>
  );
};
