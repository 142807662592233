import React, { useState } from 'react';
import cx from 'classnames';
import { RatingViewer } from '../../../../../components/RatingViewer';
import styles from '../../../CommentShop/style.module.css';

interface Props {
  onChange: (rating: number) => void;
  ratingDefault: number;
  isEditable: boolean;
}

enum Evaluations {
  HATE = 1,
  DIDNT_LIKE = 2,
  REASONABLE = 3,
  LIKE = 4,
  EXCELENT = 5,
}

export const Evaluation = ({ onChange, ratingDefault, isEditable }: Props) => {
  const [rating, setRating] = useState<number>(ratingDefault);

  const _labels = {
    [Evaluations.HATE]: 'Detestei',
    [Evaluations.DIDNT_LIKE]: 'Não gostei',
    [Evaluations.REASONABLE]: 'Razoável',
    [Evaluations.LIKE]: 'Gostei',
    [Evaluations.EXCELENT]: 'Excelente',
  };
  const ratingText = (labels: any, ratingNumber: Evaluations) => {
    const text = labels[ratingNumber] || '';
    const classSelected = cx('dib ml8 mt4', styles.vSub, {
      red: ratingNumber === Evaluations.HATE,
      'torch-red': ratingNumber === Evaluations.DIDNT_LIKE,
      'blaze-orange': ratingNumber === Evaluations.REASONABLE,
      gold: ratingNumber === Evaluations.LIKE,
      'grass-green': ratingNumber === Evaluations.EXCELENT,
    });

    return <div className={classSelected}>{text}</div>;
  };

  const ratingChanged = (newRating: number) => {
    setRating(Math.round(newRating));
    onChange(newRating);
  };

  return (
    <div className="mb12">
      <RatingViewer
        ratingValue={rating}
        ratingChanged={ratingChanged}
        half={false}
        edit={isEditable}
        className="v-top"
      />
      {ratingText(_labels, rating)}
    </div>
  );
};
