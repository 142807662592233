import { axiosInstanceWithAuth } from '../../../../services/axios-instance';

interface IRatingPayload {
  adPartsServicesId: number;
  comment: string;
  note: number;
}

export const ratingData = (serviceId: number) => {
  return axiosInstanceWithAuth.get(`/advertisement/partServices/rating/${serviceId}`, {
    headers: { 'Content-Type': 'application/json' },
  });
};

export const saveRating = (payload: IRatingPayload) => {
  return axiosInstanceWithAuth.post('/advertisement/partServices/rating', payload, {
    headers: { 'Content-Type': 'application/json' },
  });
};
